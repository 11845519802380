<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :before-close="close"
      width="60%"
    >
      <el-form
        ref="commonform"
        label-width="145px"
        :model="form"
        :rules="rules"
        v-loading="loading"
      >
        <el-form-item label="名称:" prop="name">
          <el-input
            v-model="form.name"
            maxlength="20"
            placeholder="请填写名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="唯一标识(Name):" prop="identifier">
          <el-input
            v-model="form.identifier"
            maxlength="50"
            placeholder="请填写唯一标识"
          ></el-input>
        </el-form-item>
        <el-form-item label="图标:" prop="icon">
          <el-input
            v-model="form.icon"
            maxlength="50"
            placeholder="请填写图标"
          ></el-input>
        </el-form-item>
        <el-form-item label="排序:" prop="sort">
          <el-input
            v-model="form.sort"
            maxlength="50"
            placeholder="请填写排序"
          ></el-input>
        </el-form-item>
        <el-form-item label="显示/隐藏:" prop="isShow">
          <el-switch v-model="form.isShow"></el-switch>
        </el-form-item>
        <el-form-item label="是否缓存:" prop="isCache">
          <el-switch v-model="form.isCache"></el-switch>
        </el-form-item>
        <el-form-item label="请求地址:" prop="url">
          <el-input
            v-model="form.url"
            maxlength="50"
            placeholder="请填写请求地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="component:" prop="component">
          <el-input
            v-model="form.component"
            type="textarea"
            :autosize="{ minRows: 2 }"
            maxlength="500"
            show-word-limit
            placeholder="请填写component"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-checkbox
            v-model="form.isAdmin"
            true-label="1"
            false-label="0"
            >是否为平台方使用菜单！！！</el-checkbox
          >
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button size="small" @click="close()">取 消</el-button>
        <el-button type="primary" size="small" @click="submit()"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import apiMenuSet from "@/api/apiMenuSet";
export default {
  name: "MenuAdd",
  mixins: [],
  extends: {},
  computed: {},
  components: {},
  filter: {},
  props: {
    curId: {},
    parentId: {},
    type: {
      type: String,
      default: "",
    },
  },
  watch: {
    dialogVisible(val, oldVal) {
      if (val) {
        if (this.curId) {
          this.getDetail(this.curId);
        }
        if (this.parentId) {
          this.form.id = this.parentId;
     
        }
      }
      this.title = this.type == "add" ? "添加" : "编辑";
    },
  },
  data() {
    return {
      title: "添加",
      btnLoading: false,
      loading: false,
      dialogVisible: false,
      customerTypeOptions: [],
      manageTypeOptions: [],
      form: {
        id: "",
        isAdmin:'0',
      },
      rules: {
        name: [{ required: true, trigger: "blur", message: "请填写名称" }],
        identifier: [
          { required: true, trigger: "blur", message: "请填写唯一标识Name" },
        ],
        sort: [{ required: true, trigger: "blur", message: "请填写排序" }],
        url: [{ required: true, trigger: "blur", message: "请填写请求地址" }],
        component: [
          { required: true, trigger: "blur", message: "请填写component" },
        ],
      },
    };
  },
  methods: {
    open() {
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
      this.$refs.commonform.resetFields();
      this.form = {};
    },
    submit() {
   
      this.$refs["commonform"].validate(async (valid) => {
        if (valid) {
          this.btnLoading = true;
          let params = { ...this.form };
          let res =
            this.type == "add"
              ? await apiMenuSet.saveInfomation(params)
              : await apiMenuSet.updateInfomation(params);
          if (res.code == "200") {
            this.dialogVisible = false;
            this.$emit("getListData");
            this.close();
          } else {
            this.$message.error(res.msg);
          }
          this.btnLoading = false;
        }
      });
    },
    async getDetail(id) {
      this.loading = true;
      let res = await apiMenuSet.queryInfomationById({ id: id });
      if (res.code === "200") {
        this.form = res.data;
        this.form.isAdmin=this.form.isAdmin+"";
      }
      this.loading = false;
    },
  },

  created() {},
  mounted() {},
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
</style>
